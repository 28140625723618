import Teams from './../components/Teams';
export default function About() {
    return (
        <>
            <div id="about" className="sm:flex items-center max-w-screen-xl">
                <div className="sm:w-1/2 p-10">
                    <div className="image object-center text-center">
                        <img src="https://i.imgur.com/WbQnbas.png" alt="" />
                    </div>
                </div>
                <div className="sm:w-1/2 p-5">
                    <div className="text">
                        <span className="text-gray-500 border-b-2 border-color-first uppercase">À PROPOS DE NOUS</span>
                        <h2 className="my-4 font-bold text-3xl second-color sm:text-4xl ">Au sujet de <span className="first-color">Congo Digital Services</span>
                        </h2>
                        <p className="text-gray-700">
                            Au cœur de l'Afrique centrale, CONGO DIGITAL se positionne comme un catalyseur clé dans la transformation numérique du Congo Brazzaville. Fondée par des visionnaires tels que Fred Arly Kaya, Geoffroy Michel Dibakala et Stevie Tathy-Lubasshy 
                            {/* et Marie-Michel Elenga-Norlat */}
                            , cette startup innovante s'engage à digitaliser l'économie congolaise et à réduire la fracture numérique. Notre mission est d'intégrer pleinement le Congo dans l'ère digitale en alignement avec les directives du Plan National de Développement (PND), promouvant un développement économique inclusif et durable.
                        </p>
                    </div>
                </div>
            </div>
            <Teams />
        </>
    )
}
