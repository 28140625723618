import Choose from './../Choose.jsx'
import Portfolio from './../Portfolio'
import About from './../About.jsx'
import Services from './../Services.jsx'
import Testimonial from './../Testimonial.jsx'
import Partner from '../PartnerBusiness.jsx'
import Contact from '../Contact.jsx'
export default function HomeContent() {
    return (
        <>
            <Services/>
            <About/>
            <Choose/>
            {/* <Portfolio/>
            <Testimonial/> */}
            <Partner/>
            <Contact/>
        </>
    )
}
