import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck as circleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { faGaugeHigh as gauge } from '@fortawesome/free-solid-svg-icons/faGaugeHigh'
import { faBrain as brain } from '@fortawesome/free-solid-svg-icons/faBrain'

export default function Choose() {
    const choose = [
        {
            title: "Service de haute qualité",
            icon: circleCheck,
            desc: "Nous nous engageons à fournir des services de la plus haute qualité, répondant aux besoins et aux attentes de nos clients avec professionnalisme et excellence.",
        },
        {
            title: "Style de travail agile et rapide",
            icon: gauge,
            desc: "Nous adoptons une approche agile et réactive dans notre travail, garantissant une livraison rapide et efficace de solutions adaptées aux besoins changeants de nos clients.",
        },
        {
            title: "Nous sommes experts",
            icon: brain,
            desc: "Nous utilisons les meilleures pratiques actuelles du web et intègrons les technologies les plus avancées pour transformer vos idées en réalités digitales marquantes.",
        },
        /* {
             title:"",
             icon:'',
             desc:"",
         },*/
    ]
    return (
        <>
            <div id="choose" className="bg-black">

                <section id="features"
                    className="relative block px-6 py-10 md:py-20 md:px-10  border-t border-b border-neutral-900 bg-neutral-900/30">


                    <div className="relative mx-auto max-w-5xl text-center">
                        <span className="text-gray-400 my-3 flex items-center justify-center font-medium uppercase tracking-wider">
                            Pourquoi nous choisir ?
                        </span>
                        <h2
                            className="block w-full bg-gradient-to-b from-white to-gray-400 bg-clip-text font-bold text-transparent text-3xl sm:text-4xl">
                            Parce que nous offrons des solutions numériques sur mesure, taillées pour votre succès
                        </h2>
                        <p
                            className="mx-auto my-4 w-full max-w-xl bg-transparent text-center font-medium leading-relaxed tracking-wide text-gray-400">
                            Notre ambition est de redéfinir le paysage du développement numérique. En tant que leader aspirant dans ce domaine, nous nous engageons à fournir des solutions sur mesure qui allient créativité, innovation et excellence technique.
                        </p>
                    </div>


                    <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
                        {
                            choose.map((item, index) => {
                                return (
                                    <div key={index} className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
                                        <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border ">
                                            <FontAwesomeIcon icon={item.icon} className='text-white w-6 h-6' />
                                        </div>
                                        <h3 className="mt-6 text-gray-400">{item.title}</h3>
                                        <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">{item.desc}
                                        </p>
                                    </div>
                                )
                            })
                        }
                        {/* <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
                            <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border ">
                                <FontAwesomeIcon icon={circleCheck} className='first-color w-6 h-6' />
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-color-swatch" width="24"
                                    height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M19 3h-4a2 2 0 0 0 -2 2v12a4 4 0 0 0 8 0v-12a2 2 0 0 0 -2 -2"></path>
                                    <path d="M13 7.35l-2 -2a2 2 0 0 0 -2.828 0l-2.828 2.828a2 2 0 0 0 0 2.828l9 9"></path>
                                    <path d="M7.3 13h-2.3a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h12"></path>
                                    <line x1="17" y1="17" x2="17" y2="17.01"></line>
                                </svg>
                            </div>
                            <h3 className="mt-6 text-gray-400">Service de haute qualité</h3>
                            <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">Nous nous engageons à fournir des services de la plus haute qualité, répondant aux besoins et aux attentes de nos clients avec professionnalisme et excellence.
                            </p>
                        </div>


                        <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
                            <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border ">
                                <FontAwesomeIcon icon={gauge} className='first-color w-6 h-6' />
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bolt" width="24"
                                    height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <polyline points="13 3 13 10 19 10 11 21 11 14 5 14 13 3"></polyline>
                                </svg>
                            </div>
                            <h3 className="mt-6 text-gray-400">Style de travail agile et rapide</h3>
                            <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">Nous adoptons une approche agile et réactive dans notre travail, garantissant une livraison rapide et efficace de solutions adaptées aux besoins changeants de nos clients.
                            </p>
                        </div>


                        <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
                            <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border ">
                                <FontAwesomeIcon icon={brain} className='first-color w-6 h-6' />
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-tools" width="24"
                                    height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M3 21h4l13 -13a1.5 1.5 0 0 0 -4 -4l-13 13v4"></path>
                                    <line x1="14.5" y1="5.5" x2="18.5" y2="9.5"></line>
                                    <polyline points="12 8 7 3 3 7 8 12"></polyline>
                                    <line x1="7" y1="8" x2="5.5" y2="9.5"></line>
                                    <polyline points="16 12 21 17 17 21 12 16"></polyline>
                                    <line x1="16" y1="17" x2="14.5" y2="18.5"></line>
                                </svg>
                            </div>
                            <h3 className="mt-6 text-gray-400">Nous sommes experts</h3>
                            <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">
                                Nous utilisons les meilleures pratiques actuelles du web et intègrons les technologies les plus avancées pour transformer vos idées en réalités digitales marquantes.
                            </p>
                        </div> */}


                    </div>

                    <div className="absolute bottom-0 left-0 z-0 h-1/3 w-full border-b">
                    </div>
                    <div className="absolute bottom-0 right-0 z-0 h-1/3 w-full">
                    </div>

                </section>
            </div>
        </>
    )
}
