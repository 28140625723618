import { useState } from "react"
import wymee from "./../assets/images/partner/wymee.png"
import kaylen from "./../assets/images/partner/kaylen.png"
export default function Partner() {
    const partner = [
        {
            src:wymee,
            alt:"wymee"
        },
        {
            src:kaylen,
            alt:"kaylen"
        },
        /*{
            src:"",
            alt:""
        },*/
        /*{
            src:"",
            alt:""
        },*/
    ]

    return (
        <>
            <div className="py-14">
                <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                    <h3 className="font-semibold text-2xl first-color text-center">
                        Ils nous ont fait confiance !
                    </h3>
                    <div className="mt-6">
                        <ul className="flex gap-y-6 flex-wrap items-center justify-center [&>*]:px-12 lg:divide-x
                        ">
                            {/* LOGO 1 */}
                            {
                                partner.map((item, index) => {
                                    return (
                                        <li key={index} className="flex-none">
                                            <img src={item.src} alt={item.alt} className="h-20"/>
                                        </li>
                                    )
                                })
                            }

                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
