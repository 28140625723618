import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLaptopCode as laptopCode} from '@fortawesome/free-solid-svg-icons/faLaptopCode'
import {faNetworkWired as networkWired} from '@fortawesome/free-solid-svg-icons/faNetworkWired'
import {faShieldHalved as shieldHalved} from '@fortawesome/free-solid-svg-icons/faShieldHalved'
import {faChartLine as chartLine} from '@fortawesome/free-solid-svg-icons/faChartLine'
import {faScrewdriverWrench as tools} from '@fortawesome/free-solid-svg-icons/faScrewdriverWrench'
import {faBoxArchive as archive} from '@fortawesome/free-solid-svg-icons/faBoxArchive'

export default function Services() {
    const services = [
        {
            title: "Développement Web et Mobile",
            description: "Création de sites web responsives, applications mobiles natives et hybrides pour iOS et Android",
            icon: laptopCode,
            step: [
                'E-commerce',
                'Vitrine',
                'Forum'
            ],
            path: "javascript:void(0)",
        },
        {
            title: "Développement d'Infrastructures réseaux",
            description: "Conception, déploiement et gestion d'infrastructures réseau pour assurer la connectivité et la fiabilité des systèmes informatiques.",
            icon: networkWired,
            step: [
                'Analyse des besoins',
                'Planification de l\'infrastructure',
                'Configuration et maintenance des équipements réseau'
            ],
            path: "javascript:void(0)",
        },
        {
            title: "Sécurité web et réseaux",
            description: "Mise en place de mesures de sécurité pour protéger les systèmes informatiques contre les menaces internes et externes.",
            icon: shieldHalved,
            step: [
                'Audit de sécurité',
                'Mise en place de pare-feu et de systèmes de détection d\'intrusion',
                'Formation à la sécurité informatique'
            ],
            path: "javascript:void(0)",
        },
        {
            title: "Marketing Digital",
            description: "Stratégies de marketing en ligne pour promouvoir les produits et services sur Internet.",
            icon: chartLine,
            step: [
                'Optimisation pour les moteurs de recherche (SEO)',
                'Publicité en ligne (SEA)',
                'Marketing sur les réseaux sociaux'
            ],
            path: "javascript:void(0)",
        },
        {
            title: "Maintenance et Support",
            description: "Services de maintenance et de support informatique pour assurer le bon fonctionnement des systèmes et des applications.",
            icon: tools,
            step: [
                'Dépannage et résolution des problèmes',
                'Mises à jour logicielles et matérielles',
                'Support utilisateur'
            ],
            path: "javascript:void(0)",
        },
        {
            title: "Archivage numérique",
            description: "Gérez et stockez vos données numériques de manière sécurisée et organisée avec notre service d'archivage numérique. Nous offrons des solutions complètes pour la conservation à long terme de vos fichiers, documents et informations essentielles.",
            icon: archive, 
            step: [
                'Analyse des besoins en archivage',
                'Mise en place d\'une stratégie d\'archivage personnalisée',
                'Gestion et maintenance continue de l\'archive numérique',
            ],
            path: "javascript:void(0)",
        },
        /*{
            title: "Recrutement et Promotion des Emplois dans le Numérique",
            description: "Services de recrutement spécialisés dans le domaine des technologies de l'information et de la communication (TIC).",
            icon:'',
            step: [
                'Recherche de talents qualifiés',
                'Promotion des emplois dans le secteur numérique',
                'Accompagnement des candidats dans leur recherche d\'emploi'
            ],
            path: "javascript:void(0)",
        },
        {
            title: "Formation et Développement des Compétences",
            description: "Programmes de formation sur mesure pour développer les compétences en informatique et en numérique.",
            icon:'',
            step: [
                'Évaluation des besoins en formation',
                'Conception de programmes de formation personnalisés',
                'Formation pratique et suivi des progrès'
            ],
            path: "javascript:void(0)",
        },
        {
            title: "Stratégie Digitale",
            description: "Développement de stratégies numériques pour aider les entreprises à atteindre leurs objectifs en ligne.",
            icon:'',
            step: [
                'Analyse de la présence en ligne actuelle',
                'Définition des objectifs et des KPI',
                'Mise en place de tactiques et de canaux numériques'
            ],
            path: "javascript:void(0)",
        },
        {
            title: "Design UX/UI",
            description: "Conception d'interfaces utilisateur intuitives et attrayantes pour offrir une expérience utilisateur optimale.",
            icon:'',
            step: [
                'Recherche utilisateur et analyse des besoins',
                'Wireframing et prototypage',
                'Conception visuelle et tests utilisateur'
            ],
            path: "javascript:void(0)",
        },*/
    ];
    return (
        <>
            <div id="services" className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">

                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <div>
                        <p
                            className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider  uppercase rounded-full bg-sky-500 text-white">
                            Nouveau
                        </p>
                    </div>
                    <h2
                        className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto second-color">
                        Avec nos services spécialisés, découvrez des solutions sur mesure pour répondre à vos besoins informatiques et numériques
                    </h2>
                    <p className="text-base text-gray-700 md:text-lg">Que vous cherchiez à développer votre présence en ligne, à renforcer la sécurité de vos réseaux, ou à former votre équipe aux dernières technologies, nous sommes là pour vous accompagner à chaque étape de votre parcours numérique.
                    </p>
                </div>


                <div className="grid max-w-md gap-8 row-gap-10 sm:mx-auto lg:max-w-full lg:grid-cols-3">
                    {
                        services.map((item, idx) => {
                            return (

                                <div key={idx} className="flex flex-col sm:flex-row">
                                    <div className="sm:mr-4">
                                        <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-first-color-light">
                                            <FontAwesomeIcon icon={item.icon} className='first-color w-6 h-6'/>
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className="mb-2 font-semibold leading-5">{item.title}:</h6>
                                        <p className="mb-3 text-sm text-gray-900">
                                            {item.description}
                                        </p>
                                        <ul className="mb-4 -ml-1 space-y-2">
                                            {
                                                item.step.map((step, index) => {
                                                    return (

                                                        <li key={index} className="flex items-start">
                                                            <span className="mr-1">
                                                                <svg className="w-5 h-5 mt-px first-color" stroke="currentColor" viewBox="0 0 52 52">
                                                                    <polygon stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="none"
                                                                        points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                                                                </svg>
                                                            </span>
                                                            {step}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <a href={item.path} aria-label=""
                                            className="inline-flex items-center font-semibold transition-colors duration-200 first-color-hover">Découvrir plus
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {/* <div className="flex flex-col sm:flex-row">
                        <div className="sm:mr-4">
                            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-green-50">
                                <svg className="w-12 h-12 text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                    <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none"
                                        points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                                </svg>
                            </div>
                        </div>
                        <div>
                            <h6 className="mb-2 font-semibold leading-5">Social Media Marketing:</h6>
                            <p className="mb-3 text-sm text-gray-900">
                                Our social media marketing service helps businesses establish a
                                strong online presence and engage with their target audience effectively.
                            </p>
                            <ul className="mb-4 -ml-1 space-y-2">
                                <li className="flex items-start">
                                    <span className="mr-1">
                                        <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                            <polygon stroke-width="4" stroke-linecap="round" stroke-linejoin="round"
                                                fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                                        </svg>
                                    </span>
                                    Computers
                                </li>
                                <li className="flex items-start">
                                    <span className="mr-1">
                                        <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                            <polygon stroke-width="4" stroke-linecap="round" stroke-linejoin="round"
                                                fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                                        </svg>
                                    </span>
                                    Health
                                </li>
                                <li className="flex items-start">
                                    <span className="mr-1">
                                        <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                            <polygon stroke-width="4" stroke-linecap="round" stroke-linejoin="round"
                                                fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                                        </svg>
                                    </span>
                                    Reference
                                </li>
                            </ul>
                            <a href="/" aria-label=""
                                className="inline-flex items-center font-semibold transition-colors duration-200 text-green-400 hover:text-green-800">Learn
                                more
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row">
                        <div className="sm:mr-4">
                            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-green-50">
                                <svg className="w-12 h-12 text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                    <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none"
                                        points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                                </svg>
                            </div>
                        </div>
                        <div>
                            <h6 className="mb-2 font-semibold leading-5">Content Writing and Copywriting:</h6>
                            <p className="mb-3 text-sm text-gray-900">
                                Our content writing and copywriting service offers high-quality and engaging content that captivates your audience and drives conversions.
                            </p>
                            <ul className="mb-4 -ml-1 space-y-2">
                                <li className="flex items-start">
                                    <span className="mr-1">
                                        <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                            <polygon stroke-width="4" stroke-linecap="round" stroke-linejoin="round"
                                                fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                                        </svg>
                                    </span>
                                    Computers
                                </li>
                                <li className="flex items-start">
                                    <span className="mr-1">
                                        <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                            <polygon stroke-width="4" stroke-linecap="round" stroke-linejoin="round"
                                                fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                                        </svg>
                                    </span>
                                    Health
                                </li>
                                <li className="flex items-start">
                                    <span className="mr-1">
                                        <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                            <polygon stroke-width="4" stroke-linecap="round" stroke-linejoin="round"
                                                fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                                        </svg>
                                    </span>
                                    Reference
                                </li>
                            </ul>
                            <a href="/" aria-label=""
                                className="inline-flex items-center font-semibold transition-colors duration-200 text-green-400 hover:text-green-800">Learn
                                more
                            </a>
                        </div>
                    </div> */}
                </div>

            </div>
        </>
    )
}
